.cart-button {
  &:hover {
    border: 3px solid #990424;
    cursor: pointer;
  }
  border: 3px solid transparent;
  z-index: 9;
  transition: 30ms;
  position: fixed;
  top: 300px;
  right: 47px;
  background: #ffffff;
  box-shadow: 0px 5px 29px -7px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  .cart-button__icon {
    width: 68px;
    height: 68px;
    background-image: url('../../../image/cart-icon.png');
    background-size: cover;
  }
}

.styled-num {
  font-family: Roboto;
  background: #990424;
  font-size: 18px;
  line-height: 17px;
  font-weight: 500;
  border-radius: 12px;
  height: 16px;
  min-width: 8px;
  padding: 4px 8px 4px 8px;
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  color: rgb(245, 245, 245);
  transition: black;

  left: calc(50% + 14px);
  transform: none;
}

@media (max-width: 581px) {
  .styled-num {
    font-size: 12px;
    padding: 0px 4px;
  }
  .cart-button {
    &:hover {
      border: 2px solid #990424;
    }
    top: 200px;
    right: 7px;
    width: 61px;
    height: 61px;
    .cart-button__icon {
      width: 32px;
      height: 32px;
    }
  }
}
