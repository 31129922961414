.blackout {
  background: rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
}

.modal {
  width: 581px;
  background: white;
  height: 100vh;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 16;

  .header {
    margin-top: 71px;
    margin-bottom: 47px;
    width: 421px;
    height: 91px;
    border-bottom: 2px solid #868686;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: Roboto;
      font-weight: 500;
      font-size: 40px;
      color: #3a3a3a;
    }

    .close {
      margin-top: 11px;
      width: 25px;
      height: 25px;
      opacity: 0.3;
      position: relative;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &::before,
      &::after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 25px;
        width: 2px;
        background-color: #333;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .content {
    width: 421px;
  }
}

@media (max-width: 581px) {
  .modal {
    width: 100%;

    .header {
      width: 310px;

      .close {
        margin-top: 11px;
        width: 19px;
        height: 19px;
        opacity: 0.3;
        position: relative;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }

        &::before,
        &::after {
          position: absolute;
          left: 9.5px;
          content: ' ';
          height: 19px;
          width: 2px;
          background-color: #333;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }

    .content {
      width: 310px;
    }
  }
}