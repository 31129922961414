.headerThird {
  margin-top: 60px;

  .categoriesWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categoriesItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 45px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: #4f4f4f;

    &:hover {
      color: #bd1616;
    }

    .categoriesItemName {
      font-family: Roboto;
      font-weight: 400;
      font-size: 24px;
    }
  }

  .categoriesItem.active {
    border-bottom: 2px solid #990424;
  }
}

@media (max-width: 860px) {
  .headerThird {
    display: none;
  }
}