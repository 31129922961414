.container {
  margin: auto;
  width: 1300px;
}

@media (max-width: 1300px) {
  .container {
    width: 100%;
  }
}
