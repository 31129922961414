.cards-block {
  margin-top: 44px;
  margin-bottom: 24px;
}
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.lds-dual-ring {
  position: absolute;
  top: 50%;
  left: 47%;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #bd1616 transparent #212121 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 860px) {
  .lds-dual-ring {
    top: 30%;
    left: 40%;
  }

  .cards-block {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
