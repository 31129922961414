.bannerConteiner {
  padding: 56px 30px 0px 30px;
  img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 860px) {
  .bannerConteiner {
    display: none;
  }
}
