@media (max-width: 1200px) {
  .js-data-example-ajax {
    width: 260px !important;
  }
}

.input-wrapper {
  position: relative;

  .js-data-example-ajax {
    width: 20vw;
    padding: 0 0 0 20px;
    display: block;
    border: 2px solid #990424;
    height: 35px;
    outline: none;
    background-color: #fbfbfb;
    border-radius: 6px;
  }

  input::placeholder {
    font-family: Roboto, 'Comic Sans MS', normal;
    font-size: 14px;
    font-weight: 300;
    color: #9b9b9b;
  }

  .search {
    position: absolute;
    left: 0px;
    top: 44px;
    display: block;
    width: 100%;
    box-shadow: 0px 5px 29px -7px rgba(0, 0, 0, 0.25);
    max-height: 327px;
    overflow-y: auto;
    background: #ffffff;
    z-index: 3;
    border-radius: 10px;

    .element-search {
      display: flex;
      align-items: center;
      height: 50px;
      color: #2c2c2c;
      padding: 0px 30px;
      cursor: pointer;
      background: #ffffff;
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-weight: 400;
      font-size: 12px;
    }

    :hover {
      background: #ffe6e6;
      transition-property: background-color, color;
      transition-duration: 300ms;
    }
  }

  .delete-target {
    background: #f0f0f0;
    color: #cc051a;
    display: block;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    padding: 8px 8px;
    width: 483px;
    height: 33px;
    position: absolute;
    left: 0px;
    top: 365px;
    border-radius: 0px 0px 12px 12px;
    padding-left: 25px;
    cursor: pointer;
    z-index: 1000;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }

  .vector {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
