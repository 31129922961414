.product-item {
  margin: 0 5px 50px 5px;
  width: 220px;
  height: 410px;
  background: #fff;
  overflow: hidden;
  padding: 10px 10px 0px 10px;
  border-radius: 12px;
  text-decoration: none;

  .product-item-name {
    color: #a4a4a4;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    margin-top: 5px;

    &:hover {
      color: #cc051a;
    }
  }

  .product-item-image {
    border-radius: 24px;
    width: 100%;
    height: 304px;
  }

  .product-item-price {
    margin-top: 25px;
    color: #990424;
    font-size: 24px;
    font-weight: 400;
    font-family: Roboto, 'Comic Sans MS', normal;
  }
}

@media (max-width: 860px) {
  .product-item {
    margin: 0px 4px 22px 4px;
    padding: 0px;
    width: 155px;
    height: 246px;

    .product-item-price {
      margin-top: 7px;
      font-size: 15px;
    }

    .product-item-name {
      margin-top: 1px;
      font-size: 14px;
      overflow: hidden;
      height: 14px;
    }

    .product-item-image {
      height: 204px;
    }
  }
}