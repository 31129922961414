button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.default {
  background: #fff;
  width: 174px;
  height: 56px;
  border: 2px solid #990424;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: 300;
  font-size: 24px;
  color: #990424;
  cursor: pointer;
}

.active {
  background: #990424;
  width: 174px;
  height: 56px;
  border: 2px solid #990424;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: 300;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

@media (max-width: 581px) {
  .default {
    width: 110px;
    height: 40px;
    font-size: 16px;
  }

  .active {
    width: 110px;
    height: 40px;
    font-size: 16px;
  }
}