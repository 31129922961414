.button-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  background: '#990424';
  border-radius: '6px';
}

.icon-search {
  width: 24px;
  height: 24px;
  color: white;
}
