.footerContainer {
  width: 100%;
  height: 225px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.elementContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.telephone {
  margin-left: 15px;
  font-size: 24px;
  font-weight: 500;
  color: #990424;
  font-family: Roboto;
}

.locationText {
  margin-left: 20px;
  font-size: 24px;
  color: #8a8a8a;
  font-weight: 400;
  font-family: Roboto;
}

@media (max-width: 860px) {
  .footerContainer {
    width: 100%;
    height: 88px;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .elementContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 133px;
    height: 44px;
  }

  .telephone {
    display: none;
  }

  .locationText {
    display: none;
  }

  .telephoneIcon {
    display: none;
  }

  .locationIcon {
    display: none;
  }
}